import React from "react"

import Layout from '../components/layout'
import Head from '../components/head'
import TeamsTabbed from "../components/teams_tabbed"
import { useStaticQuery } from "gatsby"

const Teams = () => {

    const data = useStaticQuery(graphql`
        query {
            allCurrentElosCsv {
                nodes {
                    elo_rank
                    wow_rank_change
                    team_nick
                    team_logo_espn
                    team
                    nfelo
                    _538_qb_adj
                    spread_val
                    wow_spread_val
                    soy_spread_val
                    season
                    week
                }
            }
        }      
    `)

    const markupData = data.allCurrentElosCsv.nodes.map(node => {
        return (
            {
                "@context": "http://schema.org",
                "@type": "SportsTeam",
                "description" : `NFL team rankings for the ${node.season} ${node.team_nick}`,
                "name": node.team_nick,
                "url": `https://www.nfeloapp.com/teams/${node.team.toLowerCase()}/${node.season}/`,
                "sameAs": `https://www.nfeloapp.com/teams/${node.team.toLowerCase()}/${node.season}/`,
                "logo" : node.team_logo_espn,
                "sport" : "https://www.nfl.com/"
            }
        )
    })

    return (
        <Layout>
            <Head
                title="NFL Power Ratings"
                pageDescription="Data driven NFL power ratings using the nfelo model"
                schemaMarkup={markupData}
            />
            <TeamsTabbed
                activeIndex={0}
            />
        </Layout>
    )
}

export default Teams

